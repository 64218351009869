import axios from 'axios'
import url from './url'
// import router from '../router'

let requests = axios.create({
    baseURL: url,
    // timeout: 5000
})

requests.interceptors.request.use((config) => {
    // console.log('config', config);
    return config

})

requests.interceptors.response.use(
    (res) => {
		if(res.data.code=='401'){
			// sessionStorage.clear()
		  //   router.push('/login');
		}else{
		    return res.data
		}
		
    },
    (err) => {
        console.error("请求错误:" + err)
    }
)

export default requests
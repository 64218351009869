import requests from './request';

// const api = '/wejddp_cs'
const api = '/wejddp'
// 获取数据
export const getData = (params) => requests({
    url: api + '/Getteam_results',
    params,
    method: 'post'
});

// 获取第几轮数据
export const GetAnswer_rounds = (params) => requests({
    url: api + '/search/round',
    params,
    method: 'get'
});

// 开始竞答
export const StartAnswer = (params) => requests({
    url: api + '/operate/startAnswer',
    params,
    method: 'post'
});

// 最后结果排名数据
export const CalculateRanking = (params) => requests({
    url: api + '/CalculateRanking',
    params,
    method: 'post'
});
// 最后结果排名数据
export const CalculateRanking1 = (params) => requests({
    url: api + '/search/teamRank',
    params,
    method: 'GET'
});

// 最后结果排名数据统计页面
export const GetRanking = (params) => requests({
    url: api + '/search/teamRank',
    params,
    method: 'get'
});

// 导入用户
export const ImportUsers = (data) => requests({
    url: api + '/import/user',
    data,
    headers: {'content-type': 'application/x-www-form-urlencoded'},
    method: 'POST'
});
// 新增导入
export const ImportUserNotJoin = (data) => requests({
    url: api + '/import/userNotJoin',
    data,
    headers: {'content-type': 'application/x-www-form-urlencoded'},
    method: 'POST'
});
// 导入题库
export const ImportQuestionbank = (data) => requests({
    url: api + '/import/question',
    data,
    headers: {'content-type': 'application/x-www-form-urlencoded'},
    method: 'POST'
});

// 导入个人积分
export const ImportRanking_of_onsite = (data) => requests({
    url: api + '/ImportRanking_of_onsite',
    data,
    headers: {'content-type': 'application/x-www-form-urlencoded'},
    method: 'POST'
});
// 个人排名
// export const Getteam_results1 = (params) => requests({
//   url: api+'/Getteam_results1',
//   params,
// 	method: 'POST'
// });

// 个人排名
export const currentPerson = (params) => requests({
    url: api + '/search/currentPerson',
    params,
    method: 'GET'
});

export const currentTeam = (params) => requests({
    url: api + '/search/currentTeam',
    params,
    method: 'GET'
});


// 当前提交人
export const Getteam_results_Personal = (params) => requests({
    url: api + '/search/person',
    params,
    method: 'GET'
});

// 导入赛队
export const ImportTeam_information = (data) => requests({
    url: api + '/import/team',
    data,
    headers: {'content-type': 'application/x-www-form-urlencoded'},
    method: 'POST'
});

// 清空数据库
export const Clear_test_data = (params) => requests({
    url: api + '/operate/clearTestData',
    params,
    method: 'DELETE'
});


// 解绑数据
export const UserloginOut = (params) => requests({
    url: api + '/operate/userid',
    params,
    method: 'POST'
});

// 差个人前200排名
export const GetPersonalRanking = (params) => requests({
    url: api + '/search/personRank',
    params,
    method: 'get'
});

// 查个人积分
export const GetRanking_of_onsite = (params) => requests({
    url: api + '/GetRanking_of_onsite',
    params,
    method: 'POST'
});

// 查看 context
export const ContextSearch = (params) => requests({
    url: api + '/context',
    params,
    method: 'GET'
});

// 增加 context
export const context = (params) => requests({
    url: api + '/context',
    params,
    method: 'POST'
});

// 删除 context
export const contextClear = (params) => requests({
    url: api + '/context',
    params,
    method: 'DELETE'
});

//
export const contextEnable = (params) => requests({
    url: api + '/context/enable/',
    params,
    method: 'POST'
});

export default {
    getData,
    GetAnswer_rounds,
    StartAnswer,
    CalculateRanking,
    GetRanking,
    ImportUsers,
    ImportUserNotJoin,
    ImportQuestionbank,
    // Getteam_results1,
    currentPerson,
    currentTeam,
    Getteam_results_Personal,
    ImportTeam_information,
    Clear_test_data,
    CalculateRanking1,
    UserloginOut,
    GetPersonalRanking,
    ImportRanking_of_onsite,
    GetRanking_of_onsite,
    ContextSearch
}

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
body {
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
* {
  margin: 0;
  padding: 0;
}
ul, li{
  list-style: none;
}

/* html,
body,
#app {
  background: #e8e8e8;
  font-size: 14px;
  color: #333;
} */
</style>

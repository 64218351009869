import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/style/style.less'
Vue.use(ElementUI)
import '@/assets/js/format.js'

import _ from 'lodash'
Vue.prototype._=_

import animated from 'animate.css'
// Vue.use(animated)

import echarts from 'echarts';
Vue.prototype.$echarts = echarts;

import api from './api/api.js'
Vue.prototype.$api = api

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import bus from './assets/js/bus.js'
Vue.prototype.$bus = bus


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'screen5',
    meta: {
      title: '大屏',
      desc: '大屏第三版，两侧滚动左侧改为个人排名，右侧改为当前提交人, 按照5632*1526分辨率搞一版, 在4的基础上背景图修改'
    },
    component: () => import(/* webpackChunkName: "about" */ '../view/screen5.vue')
  },
  {
    path: '/unbinding',
    name: 'unbinding',
    meta: {
      title: '操作',
      desc: '解绑账号的页面'
    },
    component: () => import(/* webpackChunkName: "about" */ '../view/unbinding.vue')
  },
  {
    path: '/personaRanking',
    name: 'personaRanking',
    meta: {
      title: '操作',
      desc: '个人排名导入页面'
    },
    component: () => import(/* webpackChunkName: "about" */ '../view/personaRanking.vue')
  },
  {
    path: '/right2',
    name: 'screenRight2',
    meta: {
      title: '大屏',
      desc: '右侧小屏提交人,按照固定分辨率新的一版1024x1536'
    },
    component: () => import(/* webpackChunkName: "about" */ '../view/screenRight2.vue')
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      title: '提交表单',
      desc: '提交表单'
    },
    component: () => import(/* webpackChunkName: "about" */ '../view/formPage.vue')
  }
  
  
]

const router = new VueRouter({
  routes
})

export default router
